<template>
    <div class="portfolio-index">

        <router-link to="/portfolio/logos" class="portfolio-item">
          <p class="portfolio-number">00</p>
          <h2 class="portfolio-name">Logos</h2>
          <ul class="portfolio-skills">
            <li>Logo Design</li>
          </ul>
        </router-link>

        <router-link to="/portfolio/etaerio" class="portfolio-item">
          <p class="portfolio-number">01</p>
          <h2 class="portfolio-name">Etaerio</h2>
          <ul class="portfolio-skills">
            <li>Brand Design</li>
            <li>Packaging</li>
            <li>3D Modelling</li>
          </ul>
        </router-link>

        <router-link to="/portfolio/full-plate"  class="portfolio-item">
          <p class="portfolio-number">02</p>
          <h2 class="portfolio-name">Full Plate</h2>
          <ul class="portfolio-skills">
            <li>Editorial Design</li>
            <li>Print</li>
            <li>Original Artwork</li>
          </ul>
        </router-link>

        <router-link to="/portfolio/anchor-point"  class="portfolio-item">
          <p class="portfolio-number">03</p>
          <h2 class="portfolio-name">Anchor Point</h2>
          <ul class="portfolio-skills">
            <li>Brand Design</li>
            <li>Logo</li>
            <li>Stationary</li>
          </ul>
        </router-link>

        <router-link to="/portfolio/witcher"  class="portfolio-item">
          <p class="portfolio-number">04</p>
          <h2 class="portfolio-name">The Witcher</h2>
          <ul class="portfolio-skills">
            <li>Book Cover Design</li>
            <li>Print</li>
            <li>Original Artwork</li>
          </ul>
        </router-link>

        <router-link to="/portfolio/all-these-lights"  class="portfolio-item">
          <p class="portfolio-number">05</p>
          <h2 class="portfolio-name">All These Lights</h2>
          <ul class="portfolio-skills">
            <li>Album Cover Design</li>
            <li>Original Photography</li>
            <li>Photo Editing</li>
          </ul>
        </router-link>

        <router-link to="/portfolio/inspirational-creatives"  class="portfolio-item">
          <p class="portfolio-number">06</p>
          <h2 class="portfolio-name">Inspirational Creatives</h2>
          <ul class="portfolio-skills">
            <li>Iconography</li>
            <li>Print</li>
            <li>Original Artwork</li>
          </ul>
        </router-link>

        <router-link to="/portfolio/good-time-charlies"  class="portfolio-item">
          <p class="portfolio-number">07</p>
          <h2 class="portfolio-name">Good Time Charlies</h2>
          <ul class="portfolio-skills">
            <li>Brand Design</li>
            <li>Brand Update</li>
            <li>Collateral</li>
          </ul>
        </router-link>

    </div>
</template>

<script>
export default {
    name: "Portfolio Index",

}
</script>

<style lang="scss">

</style>